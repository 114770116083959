<template>
  <div class="wrapper">
    <b-overlay
          id="overlay-background"
          :show="isBusy"
          variant="secondary"
          opacity="0.8"
          blur="2px"
          rounded="sm"
        >
      <ClientModify v-if="showClientModify" :client="client"/>
    </b-overlay>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex"
import AdminExtension from '../AdminExtension'
import ClientModify from './ClientModify'

export default AdminExtension.extend({
    name: 'ClientEdit',
    components: {
        ClientModify,
    },
    props: {
      clientId: {
        type: String
      }
    },
    data() {
        return {
            client: {},
            showClientModify: false,
        }
    },
    methods: {
        ...mapActions("meds2go/client", ["clientDetail"]),
        async loadData(){
          this.loading()
          await this.clientDetail(this.clientId).then(client => {
                this.client = client
                this.showClientModify = true // Needed to supply awaited data
              }).catch(errors => {
                console.log(errors)
              })
          this.stopLoading()
        },
    },
    mounted() {
      this.loadData()
    }
})
</script>

